// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-tooltip/dist/react-tooltip.css";

import "./src/styles/styles.scss";

import React from "react";

import { createClient, configureChains, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { mainnet } from "wagmi/chains";

import Moralis from "moralis";

const { provider, webSocketProvider } = configureChains(
  [mainnet],
  [publicProvider()]
);

const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: true,
});

console.error = () => {};
// console.log = console.warn = console.error = () => {};

export const wrapRootElement = ({ element }) => (
  <>
    <WagmiConfig client={client}>{element}</WagmiConfig>
  </>
);

export const onClientEntry = async () => {
  await Moralis.start({
    apiKey: process.env.MORALIS_API_KEY,
  });
  console.log("Moralis started successfully.");
};
