exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-gra-tsx": () => import("./../../../src/pages/gra.tsx" /* webpackChunkName: "component---src-pages-gra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-memy-tsx": () => import("./../../../src/pages/memy.tsx" /* webpackChunkName: "component---src-pages-memy-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-ownedpolacy-tsx": () => import("./../../../src/pages/ownedpolacy.tsx" /* webpackChunkName: "component---src-pages-ownedpolacy-tsx" */),
  "component---src-pages-rejestr-tsx": () => import("./../../../src/pages/rejestr.tsx" /* webpackChunkName: "component---src-pages-rejestr-tsx" */),
  "component---src-pages-sasiedzi-tsx": () => import("./../../../src/pages/sasiedzi.tsx" /* webpackChunkName: "component---src-pages-sasiedzi-tsx" */),
  "component---src-pages-ticket-tsx": () => import("./../../../src/pages/ticket.tsx" /* webpackChunkName: "component---src-pages-ticket-tsx" */),
  "component---src-pages-timetable-tsx": () => import("./../../../src/pages/timetable.tsx" /* webpackChunkName: "component---src-pages-timetable-tsx" */)
}

